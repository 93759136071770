import React, { useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import { IStoreProfile } from 'interfaces'
import { APP_URL } from 'config/app'
import { useMutationStoreSettings, useRemoveQueriesDataStoreSettings, useInvalidateStoreProfile } from 'containers/Store/hooks'
import { useRemoveQueriesDataShopsStatic } from 'containers/Market/hooks'
import {
    InfoBlock,
    SvgResource,
    Button,
    Image,
    Share,
    Popover,
} from 'components'
import { parseTpl } from 'utils/helpers'
import styleCommon from 'styles/modules/common.module.css'
import style from './StoreInfo.module.css'

type StoreInfoPropType = {
    data?: IStoreProfile
    showDesc?: boolean
}

const StoreInfo: React.FC<StoreInfoPropType> = ({ data, showDesc = true }) => {
    const { id, is_subscribe: isSubscribe } = data || {}

    const [isLoadingSubscribe, setIsLoadingSubscribe] = useState(false)

    const { update: updateStoreSettings } = useMutationStoreSettings()

    const { invalidate: invalidateStoreProfile } = useInvalidateStoreProfile()

    const { remove: removeQueriesDataStoreSettings } = useRemoveQueriesDataStoreSettings()

    const { remove: removeQueriesDataShopsStatic } = useRemoveQueriesDataShopsStatic()

    const profileUrl = useMemo(() => {
        return id ? parseTpl(APP_URL.storeProfile, { ':id': id }, { prefix: '', suffix: '' }) : ''
    }, [id])

    const isShowDesc = useMemo(() => showDesc && data?.description, [data, showDesc])

    const handlerClickSubscribe = () => {
        if (id) {
            setIsLoadingSubscribe(true)
            updateStoreSettings.mutate({
                limit: 0,
                subscribe_list: [{ id, is_subscribe: !isSubscribe }],
            }, {
                onSuccess: (res) => {
                    if (res) {
                        removeQueriesDataShopsStatic({}, { exact: false })
                        removeQueriesDataStoreSettings({}, { exact: false })
                        invalidateStoreProfile({ id: Number(id) })
                            .finally(() => {
                                setIsLoadingSubscribe(false)
                            })
                    } else {
                        setIsLoadingSubscribe(false)
                    }
                },
                onError: () => {
                    setIsLoadingSubscribe(false)
                },
            })
        }
    }

    return (
        <>
            {data && (
                <InfoBlock classes={style.wrap}>
                    <InfoBlock.Body classes={cn(style.body, { [style.body_bottomRadius]: !isShowDesc })}>
                        {data.logo_image && (
                            <NavLink className={style.link} to={profileUrl}>
                                <Image
                                    src={data.logo_image}
                                    width={400}
                                    height={300}
                                    resize={{ width: 400, height: 300, quality: 90 }}
                                />
                            </NavLink>
                        )}

                        <div className={style.controls}>
                            <Popover
                                classes={style.control}
                                classesBody={style.popoverShareBody}
                                position="bottom"
                                side="left"
                                trigger={(
                                    <SvgResource
                                        isImgTag={false}
                                        resourceKey="ic_post_share2_andr_svg"
                                        width={30}
                                        height={30}
                                    />
                                )}
                            >
                                <Share
                                    link={data.sharing_link}
                                    text={data.sharing_text}
                                />
                            </Popover>
                            <Button
                                classes={cn(
                                    style.control,
                                    style.controlLike,
                                    { [styleCommon.updating]: isLoadingSubscribe },
                                )}
                                styleType="text"
                                onClick={handlerClickSubscribe}
                            >
                                {isSubscribe ? (
                                    <SvgResource
                                        isImgTag={false}
                                        classes={style.iconLikeOn}
                                        resourceKey="ic_post_likeon2_svg"
                                        width={34}
                                        height={30}
                                    />
                                ) : (
                                    <SvgResource
                                        isImgTag={false}
                                        classes={style.iconLikeOff}
                                        resourceKey="ic_post_likeoff2_svg"
                                        width={34}
                                        height={30}
                                    />
                                )}
                            </Button>
                        </div>
                    </InfoBlock.Body>

                    {isShowDesc && (
                        <InfoBlock.Footer classes={style.footer}>
                            {data.description}
                        </InfoBlock.Footer>
                    )}
                </InfoBlock>
            )}
        </>
    )
}

export default StoreInfo
