import React, { useMemo, useState } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import {
    IUser,
    ICountry,
    ICity,
    ILanguage,
} from 'interfaces'
import { EMAIL_PUBLIC, REGEXP_DATA_IMAGE_BASE64 } from 'config/app'
import {
    Avatar,
    Button,
    Input,
    Radio,
    CustomSelect,
    CountryDataSelect,
    Cropper,
    CityDataSelect,
    DatePickerNative,
    SvgResource,
    Modal,
} from 'components'
import { AppService } from 'services'
import { useUploadFiles } from 'hooks'
import { defaultDateFormat, dateTimeFormat } from 'utils/helpers'
import styleForm from 'styles/modules/form.module.css'
import styleInput from 'components/Input/Input.module.css'
import style from './UserForm.module.css'

export enum FieldName {
    name = 'name',
    surname = 'surname',
    email = 'email',
    city = 'city',
    language = 'language',
    birthDate = 'birthDate',
    photo = 'photo',
    gender = 'gender',
    profileStatus = 'profileStatus',
}

export type FormDataType = {
    [FieldName.name]: string
    [FieldName.surname]: string
    [FieldName.email]?: string
    [FieldName.city]?: number
    [FieldName.language]?: number
    [FieldName.birthDate]?: string
    [FieldName.photo]?: string
    [FieldName.gender]?: 0 | 1
    [FieldName.profileStatus]?: string
}

export const formDataInitial: FormDataType = {
    [FieldName.name]: '',
    [FieldName.surname]: '',
}

const ACCEPT_IMAGE = 'image/jpeg,image/png,image/gif'

export type UserFormPropType = {
    countries: ICountry[]
    country: ICountry
    languages: ILanguage[]
    language: ILanguage
    user: IUser
    city?: ICity
    isSubmitting: boolean
    onDeleteUser: () => void
    onSubmit: () => void
}

const UserForm: React.FC<UserFormPropType> = ({
    countries,
    country,
    languages,
    language,
    user,
    city,
    isSubmitting,
    onDeleteUser,
    onSubmit,
}) => {
    const { t, i18n } = useTranslation()
    const { upload: uploadFiles } = useUploadFiles()

    const [countryData, setCountryData] = useState<ICountry>(country)
    const [cityData, setCityData] = useState<ICity | undefined>(city)
    const [languageData, setLanguageData] = useState<ILanguage | undefined>(language)
    const [image, setImage] = useState<string>() // base64
    const [imageCropped, setImageCropped] = useState<string>() // base64
    const [imageCroppedType, setImageCroppedType] = useState<string>() // mime type
    const [isShowModalAvatar, setIsShowModalAvatar] = useState(false)

    const { change } = useForm()
    const { hasValidationErrors } = useFormState<FormDataType>()

    const maxBirthDayRestrictions = useMemo(() => {
        const date = AppService.getDateMinimumAgeRegistration()
        return defaultDateFormat(date)
    }, [])

    const emailDescText = useMemo(() => {
        return t('for_change_email').replace('%s', '')
    }, [])

    const phoneDescText = useMemo(() => {
        return t('for_change_phone').replace('%s', '')
    }, [])

    const supportEmailMailTo = useMemo(() => {
        return user?.member_id
            ? `mailto:${EMAIL_PUBLIC}?subject=Change%20email%20please!&body=Member_id:%20${user.member_id}.%0ANew%20Email:%20`
            : ''
    }, [user])

    const supportPhoneMailTo = useMemo(() => {
        return user?.member_id
            ? `mailto:${EMAIL_PUBLIC}?subject=Change%20phone%20number%20please!&body=Member_id:%20${user.member_id}.%0ANew%20Phone%20number:%20`
            : ''
    }, [user])

    const handlerSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()
        onSubmit()
    }

    const handlerAddPhoto = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = currentTarget

        if (files?.length) {
            uploadFiles(files, { isUpload: false })
                .then(([data]) => {
                    const { imageBase64 } = data

                    if (imageBase64) {
                        setImage(imageBase64)
                        setIsShowModalAvatar(true)
                    }
                })
                .catch((e) => {
                    //
                })
        }
    }

    const handlerCropAvatar = (value: string, type: string) => {
        setImageCropped(value)
        setImageCroppedType(type)
    }

    const handlerSetAvatar = () => {
        if (imageCropped) {
            change(FieldName.photo, imageCropped)
            setImage(undefined)
            setImageCropped(undefined)
            setIsShowModalAvatar(false)
        }
    }

    return (
        <>
            <form className={cn({ [styleForm.submitting]: isSubmitting })} onSubmit={handlerSubmit}>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Field
                        <FormDataType[FieldName.photo]>
                        name={FieldName.photo}
                        render={({ input, meta }) => (
                            <div className={cn(styleForm.field, style.fieldPhoto)}>
                                <label className={style.labelPhoto}>
                                    <Input
                                        classes={styleForm.inputFile}
                                        type="file"
                                        styleType="clear"
                                        accept={ACCEPT_IMAGE}
                                        onChange={handlerAddPhoto}
                                    />
                                    <Avatar
                                        isResize={false}
                                        classes={style.labelImage}
                                        src={((input.value ?? '').includes('http') && input.value)
                                            || (REGEXP_DATA_IMAGE_BASE64.test(input.value ?? '') && input.value)
                                            || (input.value && `data:${imageCroppedType ?? 'image/jpeg'};base64,${input.value}`)}
                                        width={180}
                                        height={180}
                                    />
                                    {!input.value && (
                                        <SvgResource
                                            classes={style.iconAddPhoto}
                                            resourceKey="add_photo_svg"
                                            width={180}
                                            height={180}
                                        />
                                    )}
                                    <div className={style.labelText}>
                                        {t('change_profile_photo')}
                                    </div>
                                </label>
                            </div>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Field
                        name={FieldName.name}
                        render={({ input, meta }) => (
                            <>
                                <Input
                                    classes={cn(style.formInput, { [styleForm.invalid]: meta.dirty && !!meta.error })}
                                    styleType="dynamic"
                                    dynamicPlaceholder={`${t('firstname')}*`}
                                    {...input}
                                />
                                <div className={styleForm.error}>
                                    {meta.dirty && meta.error}
                                </div>
                            </>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Field
                        name={FieldName.surname}
                        render={({ input, meta }) => (
                            <>
                                <Input
                                    classes={cn(style.formInput, { [styleForm.invalid]: meta.dirty && !!meta.error })}
                                    styleType="dynamic"
                                    dynamicPlaceholder={`${t('lastname')}*`}
                                    {...input}
                                />
                                <div className={styleForm.error}>
                                    {meta.dirty && meta.error}
                                </div>
                            </>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Field
                        name={FieldName.profileStatus}
                        render={({ input, meta }) => (
                            <>
                                <Input
                                    classes={cn(style.formInput, { [styleForm.invalid]: meta.dirty && !!meta.error })}
                                    styleType="dynamic"
                                    dynamicPlaceholder={t('status')}
                                    {...input}
                                />
                                <div className={styleForm.error}>
                                    {meta.dirty && meta.error}
                                </div>
                            </>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Field
                        name={FieldName.email}
                        render={({ input, meta }) => (
                            <>
                                <Input
                                    classes={cn(style.formInput, { [styleForm.invalid]: meta.dirty && !!meta.error })}
                                    styleType="dynamic"
                                    inputMode="email"
                                    dynamicPlaceholder={t('email')}
                                    disabled={!!user.email}
                                    {...input}
                                />
                                <div className={styleForm.error}>
                                    {meta.dirty && meta.error}
                                </div>
                                {user.email && (
                                    <div className={style.formFieldDesc}>
                                        {emailDescText}
                                        {' '}
                                        <a className={style.descLink} href={supportEmailMailTo}>
                                            {t('to_support')}
                                        </a>
                                    </div>
                                )}
                            </>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Field
                        name={FieldName.birthDate}
                        render={({ input, meta }) => (
                            <div className={cn(styleForm.field, styleInput.dynamic)}>
                                <div
                                    className={cn(
                                        styleInput.placeholder,
                                        { [styleInput.placeholder_active]: !!input.value },
                                    )}
                                >
                                    {t('borndate')}
                                </div>
                                <DatePickerNative
                                    classesWrap={cn(
                                        style.datePickerWrap,
                                        { [style.datePickerWrap_active]: !!input.value },
                                    )}
                                    classes={cn(style.datePicker, { [style.datePicker_active]: !!input.value })}
                                    max={maxBirthDayRestrictions}
                                    {...input}
                                >
                                    {input.value && dateTimeFormat(input.value, i18n.language)}
                                </DatePickerNative>
                                <SvgResource
                                    isImgTag={false}
                                    classes={cn(
                                        styleForm.fieldIcon,
                                        styleForm.fieldIcon_right,
                                        style.formFieldIcon,
                                    )}
                                    resourceKey="wallet_icon_calendar_svg"
                                    width={30}
                                    height={30}
                                />
                            </div>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Field
                        name={FieldName.city}
                        render={({ input, meta }) => (
                            <>
                                <div className={cn(
                                    styleForm.field,
                                    styleInput.dynamic,
                                    styleForm.group,
                                    { [styleForm.invalid]: meta.dirty && !!meta.error },
                                )}
                                >
                                    <div className={cn(styleInput.placeholder, styleInput.placeholder_active)}>
                                        {t('city')}
                                    </div>
                                    <CountryDataSelect
                                        isShowSelectArrow
                                        classes={cn(
                                            style.formFieldContent,
                                            styleForm.groupItem,
                                            styleForm.groupItem_minWidth,
                                        )}
                                        classesField={style.formSelectField}
                                        countries={countries}
                                        selected={countryData}
                                        onChange={(value) => {
                                            input.onChange(undefined)
                                            setCityData(undefined)
                                            setCountryData(value)
                                        }}
                                    />
                                    <CityDataSelect
                                        classes={cn(
                                            style.formFieldContent,
                                            style.formFieldContent_maxWidth,
                                            styleForm.groupItem,
                                            styleForm.groupItem_maxWidth,
                                        )}
                                        classesField={style.formSelectField}
                                        countryId={countryData?.id}
                                        countryLang={countryData?.lang}
                                        selected={cityData}
                                        onChange={(value) => input.onChange(value.id)}
                                    />
                                </div>
                                <div className={styleForm.error}>
                                    {meta.dirty && meta.error}
                                </div>
                            </>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Field
                        name={FieldName.language}
                        render={({ input }) => (
                            <div className={cn(styleForm.field, styleInput.dynamic)}>
                                <div className={cn(styleInput.placeholder, styleInput.placeholder_active)}>
                                    {t('Language')}
                                </div>
                                <CustomSelect.Select classes={style.formFieldContent}>
                                    <CustomSelect.Field classes={cn(style.formSelectField)}>
                                        {languageData?.name}
                                    </CustomSelect.Field>
                                    <CustomSelect.Options>
                                        {languages?.map((item) => (
                                            <CustomSelect.Option
                                                isActive={item.id === languageData?.id}
                                                id={item.id}
                                                key={item.id}
                                                onClick={(langId) => {
                                                    setLanguageData(languages.find((lang) => lang.id === langId))
                                                    input.onChange(langId)
                                                }}
                                            >
                                                {item.name}
                                            </CustomSelect.Option>
                                        ))}
                                    </CustomSelect.Options>
                                </CustomSelect.Select>
                            </div>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Field
                        name={FieldName.gender}
                        render={({ input }) => (
                            <>
                                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_gray)}>
                                    {t('gender')}
                                </div>
                                <div className={style.formFieldColumn}>
                                    <Radio
                                        {...input}
                                        classes={style.formRadio}
                                        classesText={style.formRadioText}
                                        text={t('female')}
                                        value="0"
                                        checked={input.value === 0}
                                        onChange={(e) => input.onChange(Number(e.currentTarget.value))}
                                    />
                                    <Radio
                                        {...input}
                                        classes={style.formRadio}
                                        classesText={style.formRadioText}
                                        text={t('male')}
                                        value="1"
                                        checked={input.value === 1}
                                        onChange={(e) => input.onChange(Number(e.currentTarget.value))}
                                    />
                                </div>
                            </>
                        )}
                    />
                </div>
                {!!user?.user_phones?.length && (
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        {user.user_phones.map((item, index) => (
                            <div className={cn(styleForm.row, styleForm.row_20)} key={item.phone_number}>
                                <Input
                                    disabled
                                    classes={style.formInput}
                                    value={item.phone_number}
                                    styleType="dynamic"
                                    dynamicPlaceholder={`${t('phone')} ${user.user_phones.length > 1 ? index + 1 : ''}`}
                                />
                            </div>
                        ))}
                        <div className={style.formFieldDesc}>
                            {/* TODO будет использоваться категория службы поддержки id=11 */}
                            {phoneDescText}
                            {' '}
                            <a className={style.descLink} href={supportPhoneMailTo}>
                                {t('to_support')}
                            </a>
                        </div>
                    </div>
                )}
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Input
                        disabled
                        classes={style.formInput}
                        styleType="dynamic"
                        dynamicPlaceholder="ID"
                        value={user?.member_id}
                    />
                </div>
                <div className={cn(styleForm.controls, styleForm.controls_40)}>
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <Button
                            fullWidth
                            styleType="bordered"
                            size="size44"
                            text={t('remove_account')}
                            onClick={onDeleteUser}
                        />
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <Button
                            fullWidth
                            classes={style.formButtonSubmit}
                            text={t('save')}
                            size="size44"
                            type="submit"
                            disabled={hasValidationErrors}
                        />
                    </div>
                </div>
            </form>

            <Modal
                isOpen={isShowModalAvatar}
                size="medium"
                classes=""
                onClose={() => setIsShowModalAvatar(false)}
            >
                <Modal.Header title={t('edit_photo')} />
                <Modal.Body>
                    <Cropper
                        source={image}
                        onCrop={handlerCropAvatar}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        text={t('install')}
                        size="size40"
                        onClick={handlerSetAvatar}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default UserForm
